@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*{
  font-family: 'Poppins', sans-serif;
}

body{
  /* display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; */
  /* background: #c2e3f1; */
}
.action{
  display: flex;
  justify-content: end;
  margin: 5px;
}

button:hover {
  color: #498eca;
  cursor: pointer;
}
.container{
  position: relative;
}

.container .card{
  /* position: relative;
  width: 320px;
  height: 450px;
  background: #c2e3f1;
  border-radius: 20px;
  overflow: hidden; */
  position: relative;
  width: 250px;
  height: 400px;
  background: #c2e3f1;
  border-radius: 20px;
  overflow: hidden;
}

.container .card:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #498eca;
  clip-path: circle(250px at 80% -20%);
}
/* @media (max-width: 700px) {
  .container .card{
    width: 200px;
    height: 350px;  
  }
  .container .card:before{
    clip-path: circle(200px at 80% -20%);
  }
} */
.container .card .imgBx{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 70%;
}

.container .card .imgBx img{
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-40deg);
    width: 45%
}

.container .card .contentBx{
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  /* z-index: 10; */
}

.container .card .contentBx h3{
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  color: #498eca;
  margin: -5px;
}

.container .card .contentBx .size, .container .card .contentBx .color {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.container .card:hover .contentBx .size{
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container .card .contentBx .size h3{
    margin: 10px;
}

.container .card .contentBx .size span{
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  color: #111;
  background: #fff;
  margin: 0 5px;
  color: #111;
  border-radius: 4px;
  cursor: pointer;
}


.ribbon-2 {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */
  
  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: 
    polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  background: #1976d2;
  color: #fff;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
}

.box {
  width:30%;
  height:150px;
  margin:0px auto 0;
  border: 1px solid lightgray;
  position:relative;
  border-radius:20px;
  justify-content: center;
    align-items: center;
    align-self: center;
    display: flex;
    text-align: center;
}
.box.active{
  background:#c2e3f1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media (max-width: 900px) {
  /* For mobile phones: */
  .box{
    width:15em;
    height: 140px;
  }
 

}

